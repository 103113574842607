import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

const initialState = (archived = false, deleted = false, important = false, waitingOn = false, overdue = false, folder = null) => ({
  timelines: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  requestData: {
    page: 1,
    pageSize: 50,
    order: 1,
    search: '',
    archived: false,
    deleted: false,
    important: false,
    waitingOn: false,
    overdue: false,
    folder: null,
  },
  timelineCounts: {
    totalCount: 0,
    unSeenCount: 0,
    waitingOnCount: 0,
    importantCount: 0,
    overdueCount: 0,
    binCount: 0,
    archiveCount: 0,
    archiveUnSeenCount: 0,
    sentCount: 0,
  },
  contactsFilter: [],
  isLoading: true,
  isContactsFilterLoading: true,
  contactsFilterLoadingError: null,

});

export const state = initialState();

export const getters = {
  contactsFilter: s => s.contactsFilter,
  timelines: s => s.timelines,
  isLoading: s => s.isLoading,
  contactsFilterLoadingError: s => s.contactsFilterLoadingError,

  isContactsFilterLoading: s => s.isContactsFilterLoading,
  getTimelineById: (s) => (id) => {
    return s.timelines.items.find(item => item.id === id);
  },
  timelineCounts: s => s.timelineCounts,
  requestData: s => s.requestData,
  archived: s => s.requestData.archived,
  deleted: s => s.requestData.deleted,
  important: s => s.requestData.important,
  waitingOn: s => s.requestData.waitingOn,
  overdue: s => s.requestData.overdue,
  folder: s => s.requestData.folder,

};

export const actions = {
  updateRequestData({ commit, dispatch }, requestData) {
    dispatch('fetchTimelines', requestData);
  },
  addTaskIntoTimeline({ commit }, task) {
    commit("ADD_TASK", task);
  },
  collapse({ commit }, request) {
    commit("SET_ISCOLLAPSE", request);
  },
  collapseAll({ commit }) {
    commit("SET_ISCOLLAPSE_TRUE_All");
  },
  fetchCount({ dispatch }) {
    dispatch('fetchTimelineCount');
  },
  fetchContact({ dispatch }) {
    dispatch('fetchContactsFilter');
  },
  addNoteIntoTimeline({ commit }, note) {
    commit("ADD_NOTE", note);
  },
  addEventIntoTimeline({ commit }, newEvent) {
    commit("ADD_EVENT", newEvent);
  },
  // we need method for removing
  async fetchTimeline({ commit, dispatch }, timelineId) {
    try {
      return await api.getById(`timeline`, timelineId).then(response => {
        if (response) {
          commit("SET_TIMELINE", response.data)
          dispatch('fetchTimelineCount')
        }
        return true
      })
    } catch (err) {
      commit("SET_ERROR", err)
      return false
    }
  },
  async fetchContactsFilter({ commit, rootGetters }) {
    const user = rootGetters['user/user'];
    try {
      return await api.get(`timeline/contactFilter`, { email: user.email }).then(response => {
        if (response) {
          commit("SET_CONTACTS_FILTER", response.data)
        }
        return true
      })
    } catch (err) {
      commit("SET_ERROR", err)
      return false
    }
  },
  async fetchEmailBody({ commit }, { timelineId, emailIds }) {
    return await api.getEmailBody(`email/body`, { emailId: emailIds }).then(response => {
      if (response) {
        commit("SET_EMAILDETAIL", { timelineId: timelineId, emailId: emailIds, data: response.data });
      }
      return true;
    })
  },
  async archiveTimeline({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email archived'
    } else {
      message = count + ' emails archived'
    }

    return await api.insert(`timeline/archive`, request)
      .then((response) => {
        if (response) {
          dispatch('fetchTimelines', null);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async unArchiveTimeline({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email moved to inbox'
    } else {
      message = count + ' emails moved to inbox'
    }

    return await api.insert(`timeline/unArchive`, request)
      .then((response) => {
        if (response) {
          dispatch('fetchTimelines', null);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async deleteTimeline({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email deleted'
    } else {
      message = count + ' emails deleted'
    }

    return await api.deleteWithRequest(`timeline/moveToBin`, request)
      .then((response) => {
        if (response) {
          commit("REMOVE", request.ids);
          dispatch('fetchTimelines', null);
          $toast.open({
            message: message,
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async moveToInbox({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email moved to inbox'
    } else {
      message = count + ' emails moved to inbox'
    }

    return await api.insert(`timeline/moveToInbox`, request)
      .then((response) => {
        if (response) {
          commit("REMOVE", request.ids);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email sets as important'
    } else {
      message = count + ' emails sets as important'
    }

    return api.insert(`timeline/markAsImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", { important: true, response: response.data });
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsNotImportant({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email sets as not important'
    } else {
      message = count + ' emails sets as not important'
    }

    return api.insert(`timeline/markAsNotImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", { important: false, response: response.data });
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleRead({ commit, dispatch }, timelineId) {
    return api.toggle(`timeline/${timelineId}/toggleRead`)
      .then((response) => {
        if (response) {
          commit("UPDATE", timelineId);
          $toast.open({
            message: 'Email updated status',
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsRead({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email marked as read'
    } else {
      message = count + ' emails marked as read'
    }

    return api.insert(`timeline/markAsRead`, request)
      .then((response) => {
        if (response && response.status === 200) {
          commit("MARK_AS_READ", request.ids);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async markAsUnRead({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email marked as unread'
    } else {
      message = count + ' emails marked as unread'
    }

    return api.insert(`timeline/markAsUnRead`, request)
      .then((response) => {
        if (response && response.status === 200) {
          commit("MARK_AS_UNREAD", request.ids);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async toggleWaitingOn({ commit, dispatch }, request) {
    let message = ''

    if (request.dueDate) {
      message = 'Email sets waiting-on'
    } else {
      message = 'Email sets no waiting'
    }

    return api.insert(`timeline/toggleWaitingOn`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_WAITING", request.timelineId);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('fetchTimelineCount');
          return true;
        }
        return false;
      });
  },
  async fetchTimelines({ commit, state }, newRequestData) {
    let requestData = null;
    if (newRequestData === null) {
      requestData = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        archived: state.requestData.archived,
        deleted: state.requestData.deleted,
        important: state.requestData.important,
        waitingOn: state.requestData.waitingOn,
        overdue: state.requestData.overdue,
        folder: state.requestData.folder,
      }
    } else {
      requestData = newRequestData;
    }
    commit('SET_REQUEST_DATA', requestData);
    commit('SET_LOADING', true);

    try {
      await fetchTimelinesWithRetry(commit, requestData, 0);
    } finally {
      commit('SET_LOADING', false);
    }

  },
  async fetchTimelineCount({ commit }) {
    try {
      const response = await api.get(`timeline/count`, {});
      if (response.data) {
        commit("SET_COUNT", response.data);
        return true;
      }
      return false; // Handle case where response.data is falsy
    } catch (error) {
      console.error('Error fetching timeline count:', error);
      return false; // Return false or handle error appropriately
    }
  },
  loadSelectedSubMenu({ dispatch, state }, request) {
    // dispatch('fetchTimelines', request);
    console.log(request)
  },
  // loadSelectedSubMenu({ dispatch, state }, menuName) {
  //   let request = {
  //     page: 1,
  //     pageSize: state.requestData.pageSize,
  //     order: 1,
  //     search: '',
  //     important: false,
  //     archived: false,
  //     deleted: false,
  //     waitingOn: false,
  //     overdue: false,
  //     folder: null,
  //   };
  //   switch (menuName) {
  //     case 'important':
  //       request = { ...request, important: true, deleted: false, archived: false, waitingOn: false, overdue: false, folder: null };
  //       break;
  //     case 'inbox':
  //       request = { ...request, important: false, archived: false, deleted: false, waitingOn: false, overdue: false, folder: null };
  //       break;
  //     case 'archived':
  //       request = { ...request, important: false, archived: true, deleted: false, waitingOn: false, overdue: false, folder: null };
  //       break;
  //     case 'waitingOn':
  //       request = { ...request, important: false, archived: false, deleted: false, waitingOn: true, overdue: false, folder: null };
  //       break;
  //     case 'overdue':
  //       request = { ...request, important: false, archived: false, waitingOn: false, overdue: true, deleted: false, folder: null };
  //       break;
  //     case 'sent':
  //       request = { ...request, important: false, deleted: false, archived: false, waitingOn: false, overdue: false, folder: 4 };
  //       break;
  //     case 'bin':
  //       request = { ...request, important: false, deleted: true, archived: false, waitingOn: false, overdue: false, folder: null };
  //       break;
  //     default:
  //       break;
  //   }
  //   console.log(request)
  //   dispatch('fetchTimelines', request);
  // },
};

async function fetchTimelinesWithRetry(commit, requestData, retryCount) {
  try {
    let response = null;
    let requestParams = { ...requestData };
    if (requestParams.archived === false) {
      delete requestParams.archived;
    }
    if (requestParams.deleted === false) {
      delete requestParams.deleted;
    }
    if (requestParams.important === false) {
      delete requestParams.important;
    }
    if (requestParams.waitingOn === false) {
      delete requestParams.waitingOn;
    }
    if (requestParams.overdue === false) {
      delete requestParams.overdue;
    }
    if (requestParams.folder === null) {
      delete requestParams.folder;
    }
    response = await api.get('timeline', requestParams);
    if (response) {
      commit("SET_TIMELINES", response.data);
      commit('SET_LOADING', false);
      // let fetchEmailBodies = api.getEmailBody(`email/body`, { emailId: emailIds });
    }
  } catch (error) {
    console.error('Error fetching timelines:', error);
    if (retryCount < 3) {
      await new Promise(resolve => setTimeout(resolve, 10000));
      await fetchTimelinesWithRetry(commit, requestData, retryCount + 1);
    } else {
      console.error('Max retries reached. Failed to fetch timelines.');
    }
  }
}
export const mutations = {
  RESET_STATE(state, archived, deleted, important, waitingOn, overdue, folder) {
    Object.assign(state, initialState(archived, deleted, important, waitingOn, overdue, folder));
  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_CONTACTS_FILTER(s, contacts) {
    s.contactsFilter = contacts;
    s.isContactsFilterLoading = false;
  },
  SET_COUNT(s, response) {
    s.timelineCounts = response
  },
  REMOVE(s, ids) {
    s.timelines.items = s.timelines.items.filter(item => !ids.includes(item.id));
  },
  ADD_TASK(s, task) {
    const timeline = s.timelines.items.find(t => t.id === task.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: task.id,
        assignDate: task.createDate,
        isDeleted: task.isDeleted,
        isImportant: task.isImportant,
        itemType: 2,
        timelineId: task.timelineId,
        title: task.title
      })
      timeline.tasks.push(task);
      timeline.taskCount++;
    }
  },
  ADD_NOTE(s, note) {
    const timeline = s.timelines.items.find(t => t.id === note.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: note.id,
        assignDate: note.createDate,
        isDeleted: note.isDeleted,
        isImportant: note.isImportant,
        itemType: 4,
        timelineId: note.timelineId,
        title: note.title
      })
      timeline.notes.push(note);
      timeline.noteCount++;
    }
  },
  ADD_EVENT(s, newEvent) {
    const event = newEvent.data[0]
    const timeline = s.timelines.items.find(t => t.id === event.timelineId);
    if (timeline) {
      timeline.timelineItems.push({
        id: event.id,
        assignDate: event.createDate,
        isDeleted: event.isDeleted,
        isImportant: event.isImportant,
        itemType: 3,
        timelineId: event.timelineId,
        title: event.summary
      })
      timeline.events.push(event);
      timeline.eventCount++;
    }
  },
  SET_ISCOLLAPSE(s, request) {
    const index = s.timelines.items.findIndex(item => item.id === request.id);
    if (index !== -1) {
      s.timelines.items[index].isCollapsed = !s.timelines.items[index].isCollapsed;

    };

  },

  SET_TIMELINE(s, timeline) {
    const index = s.timelines.items.findIndex(item => item.id === timeline.id);
    const foundItem = s.timelines.items[index];
    timeline.seen = true;
    timeline.isCollapsed = foundItem.isCollapsed
    if (index === -1) {
      s.timelines.items.push(timeline);
    } else {
      s.timelines.items[index] = timeline;
    }
    s.isLoading = false;
  },
  SET_TIMELINES(s, data) {
    s.timelines = {};
    s.timelines = data;
  },

  SET_REQUEST_DATA(s, data) {
    s.requestData.page = data.page
    s.requestData.pageSize = data.pageSize
    s.requestData.order = data.order
    s.requestData.search = data.search
    s.requestData.archived = data.archived
    s.requestData.deleted = data.deleted
    s.requestData.important = data.important
    s.requestData.waitingOn = data.waitingOn
    s.requestData.overdue = data.overdue
    s.requestData.folder = data.folder
  },

  UPDATE_IMPORTANT(s, data) {
    data.response.forEach(response => {
      const timeline = s.timelines.items.find(t => t.id === response.id);
      if (timeline) {
        timeline.isImportant = data.important;
      }
    })
  },
  MARK_AS_READ(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.items.find(t => t.id === response);
      if (timeline) {
        timeline.seen = true;
      }
    })
  },
  MARK_AS_UNREAD(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.items.find(t => t.id === response);
      if (timeline) {
        timeline.seen = false;
      }
    })
  },

  UPDATE_WAITING(s, timelineId) {
    const timeline = s.timelines.items.find(t => t.id === timelineId);
    if (timeline) {
      if (timeline.waitingOn === true) {
        s.timelineCounts.waitingOnCount--
      }
      else {
        s.timelineCounts.waitingOnCount++
      }
      timeline.waitingOn = !timeline.waitingOn;
    }
  },
  SET_ERROR(s, err) {
    s.contactsFilterLoadingError = err;
    s.isContactsFilterLoading = false;
  },
  SET_ISCOLLAPSE_TRUE_All(state) {
    state.timelines.items.forEach((timeline) => {
      timeline.isCollapsed = true;
    });
  },

}
export default {
  state,
  getters,
  actions,
  mutations
};