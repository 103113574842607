import api from '@/lib/services/general.service'

export const state = {
    emails: {
        items: [],
        index: 0,
        totalCount: 0,
        totalPages: 0,
        hasPreviousPage: 0,
        hasNextPage: 0,
    },
    requestData: {
        page: 1,
        order: 1,
        pageSize: 50
    },
    isLoading: false,
}
export const getters = {
    draftEmails: s => s.emails,
    isLoading: s => s.isLoading,
    draftCounts: s => s.emails.totalCount,
    requestData: s => s.requestData,
};
export const actions = {
    async fetchEmails({ commit, state }, newRequestData) {
        let requestData = null;
        if (newRequestData === null) {
            requestData = {
                page: state.requestData.page,
                order: state.requestData.order,
                pageSize: state.requestData.pageSize,
            }
        } else {
            requestData = newRequestData;
        }
        commit('SET_REQUEST_DATA', requestData);
        commit('SET_LOADING', true);
        try {
            await fetchDraftsWithRetry(commit, requestData, 0);
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async eraseEmail({ dispatch, commit, state }, requestData) {
        commit('SET_LOADING', true);
        const request = {
            emailIds: [],
            providerEmailIds: {},
        };
        const allUids = [];

        requestData.forEach((email) => {
            if (email.provider !== null && email.uId) {
                const provider = email.provider;
                if (!request.providerEmailIds[provider]) {
                    request.providerEmailIds[provider] = [];
                }
                request.providerEmailIds[provider].push(email.uId.toString());
                allUids.push(email.uId.toString());

            }
        });
        try {
            await api.insert(`email/eraseDraft`, request);

            const emailsTotalCount = state.emails.totalCount || 0;
            const pageSize = state.requestData.pageSize || 0;
            if (emailsTotalCount > pageSize) {
                setTimeout(() => {
                    dispatch('fetchEmails', null);
                }, 3000);
                console.log("load kon")
            } else {
                commit('REMOVE_EMAIL', allUids);
                commit('SET_LOADING', false);

            }
            return true;
        } catch (error) {
            console.error("Error erasing emails:", error);
            throw error;
        }
    },
}
async function fetchDraftsWithRetry(commit, requestData, retryCount) {
    try {
        let response = null;
        let requestParams = { ...requestData };
        response = await api.get(`email/draft`, requestParams)
        if (response) {
            commit("SET_EMAILS", response.data);
            commit('SET_LOADING', false);
        }
    } catch (error) {
        console.error('Error fetching Emails:', error);
        if (retryCount < 3) {
            await new Promise(resolve => setTimeout(resolve, 10000));
            await fetchDraftsWithRetry(commit, requestData, retryCount + 1);
        } else {
            console.error('Max retries reached. Failed to fetch Emails.');
        }
    }
}
export const mutations = {
    SET_REQUEST_DATA(s, data) {
        s.requestData.page = data.page
        s.requestData.pageSize = data.pageSize
        s.requestData.order = data.order
        s.requestData.search = data.search
    },
    SET_EMAILS(s, emails) {
        s.emails = {}
        s.emails = emails;
    },
    SET_LOADING(s, loading) {
        s.isLoading = loading;

    },
    REMOVE_EMAIL(state, emailIds) {
        const initialCount = state.emails.items.length;
        state.emails.items = state.emails.items.filter(email => !emailIds.includes(email.uId));
        const removedCount = initialCount - state.emails.items.length;
        state.emails.totalCount -= removedCount;

    }

}
export default {
    state,
    getters,
    actions,
    mutations
};