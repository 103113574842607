export const utils = {
  parsBoolean(value) {
    switch (value?.toLowerCase()?.trim()) {
      case "true":
      case "yes":
      case "1":
        return true;

      case "false":
      case "no":
      case "0":
      case null:
      case undefined:
        return false;

      default:
        return JSON.parse(value);
    }
  },

  previewText(content, count = null) {
    if (!content) {
      return
    }

    let result = content.replace(/<\/?[^>]+(>|$)/g, '').trim()

    if (count > 0 && result.length > count) {
      result = result.substring(0, count) + '...'
    }
    return result
  },

  formatDateTime(date) {
    const inputDateTime = new Date(date)
    const today = new Date()
    today.setHours(0, 0, 0, 0)
    const isToday =
      inputDateTime.getTime() >= today.getTime() &&
      inputDateTime.getTime() < today.getTime() + 24 * 60 * 60 * 1000

    const timeDiff = today - inputDateTime
    const daysDifference = Math.floor(timeDiff / (1000 * 60 * 60 * 24))

    // First, ensure that the input date is valid
    if (isNaN(inputDateTime.getTime())) {
      return 'Invalid Date'
    }

    // Get the time zone
    let timezoneId = Intl.DateTimeFormat().resolvedOptions().timeZone

    // Get the UTC offset for the timezone
    const offset = new Date().getTimezoneOffset() // In minutes
    const offsetHours = offset / 60 // Convert minutes to hours

    // Add the offset to the input date
    const adjustedDateTime = new Date(inputDateTime.getTime() - offsetHours * 60 * 60 * 1000)
    if (isToday) {
      // return toAmPmTimeString(adjustedDateTime)
      return adjustedDateTime.toLocaleString('en-GB', {
        timeZone: timezoneId,
        hour: 'numeric',
        minute: 'numeric',
        hour12: true // Set to true if you want 12-hour format
      })
    } else {
      if (daysDifference === 0) {
        return 'Yesterday, ' + adjustedDateTime.toLocaleString('en-GB', {
          timeZone: timezoneId,
          hour: 'numeric',
          minute: 'numeric',
          hour12: true // Set to true if you want 12-hour format,
        })
      } else if (daysDifference <= -1) {
        return adjustedDateTime.toLocaleString('en-GB', {
          timeZone: timezoneId,
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: 'numeric',
          minute: 'numeric',
          hour12: true // Set to true if you want 12-hour format,        
        })
      } else {
        //past
        if (daysDifference < 30) {
          return daysDifference + ' days ago'
          //  + adjustedDateTime.toLocaleString('en-GB', {
          //   timeZone: timezoneId,
            // hour: 'numeric',
            // minute: 'numeric',
            // hour12: true       
          // })
        } else {
          return adjustedDateTime.toLocaleString('en-GB', {
            timeZone: timezoneId,
            // weekday: 'short',
            month: 'short',
            day: 'numeric',
          })
        }
      }
    }
  },

  consolog(object) {
    console.table(object)
  },
}

export const timelineUtils = {
  getEmailMessage(timeline, timelineItemId, log = false) {
    if (!timeline?.emailMessages || !timelineItemId) {
      if (log) {
        console.error('timelineUtils.getEmailMessage(): Invalid input parameter(s)!')
      }
      return
    }
    const emailMessage = timeline.emailMessages.find((t) => t.id === timelineItemId)
    if (emailMessage) {
      if (log) {
        console.log('emailMessage:')
        console.table(emailMessage)
      }
      const emailAttachments = timeline.timelineItems.filter(
        (q) => q.itemType === 5 && q.fileInfo?.externalEntityId === emailMessage.id
      )
      if (log) {
        console.log('emailAttachments:')
        console.table(emailAttachments)
      }
      return { ...emailMessage, emailAttachments }
    } else {
      if (log) {
        console.log('timelineUtils.getEmailMessage(): Return no match.')
      }
      return
    }
  },
  getNote(timeline, timelineItemId, log = false) {
    if (!timeline?.notes) {
      if (log) {
        console.error('timelineUtils.getNote(): Invalid input parameter(s)!')
      }
      return
    }
    const note = timeline.notes.find((q) => q.id === timelineItemId)
    if (note) {
      if (log) {
        console.log('note:')
        console.table(note)
      }
      return { ...note, content: note.content }
    } else {
      if (log) {
        console.log('timelineUtils.getNote(): Return no match.')
      }
      return
    }
  },
  getTask(timeline, timelineItemId, log = false) {
    if (!timeline?.tasks) {
      if (log) {
        console.error('timelineUtils.getTask(): Invalid input parameter(s)!')
      }
      return
    }
    const task = timeline.tasks.find((q) => q.id === timelineItemId)
    if (task?.status != 'done') {
      if (log) {
        console.log('task:')
        console.table(task)
      }
      return { ...task, content: task.content }
    } else {
      if (log) {
        console.log('timelineUtils.getTask(): Return no match.')
      }
      return
    }
  },
  getEvent(timeline, timelineItemId, log = false) {
    if (!timeline?.events) {
      if (log) {
        console.error('timelineUtils.getEvent(): Invalid input parameter(s)!')
      }
      return
    }
    const event = timeline.events.find((q) => q.id === timelineItemId)
    if (event) {
      if (log) {
        console.log('event:')
        console.table(event)
      }
      return { ...event, description: event.description }
    } else {
      if (log) {
        console.log('timelineUtils.getEvent(): Return no match.')
      }
      return
    }
  },
}