import api from '@/lib/services/general.service';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();

const initialState = (important = false, isPreset = false) => ({
  notes: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  requestData: {
    page: 1,
    pageSize: 50,
    order: 1,
    search: '',
    important: false,
    isPreset: false,
  },
  presetNotes: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  totalCount: 0,
  importantCount: 0,
  presetCount: 0,
  pagesLoaded: new Set(),
});
export const state = initialState();
export const getters = {
  notes: (state) => {
    let { items, index, totalCount, totalPages, } = state.notes;
    let { page, pageSize, } = state.requestData;
    let activeItems = items.filter(item => !item.isDeleted);

    let start = (page - 1) * pageSize;
    let end = start + pageSize;
    let paginatedNotes = activeItems.slice(start, end);

    return {
      items: paginatedNotes,
      index: page,
      totalCount: paginatedNotes.length,
      totalPages: totalPages,
      hasPreviousPage: page > 1,
      hasNextPage: end < items.length,
    };
  },
  isLoading: s => s.isLoading,
  totalCount: s => s.totalCount,
  importantCount: s => s.importantCount,
  presetCount: s => s.presetCount,
  pagesLoaded: s => s.pagesLoaded,
  important: s => s.requestData.important,
  isPreset: s => s.requestData.isPreset,
  presetNotes: s => s.presetNotes,
};

export const actions = {
  async fetchNotesAndCounts({ commit, dispatch, state }, newRequestData) {
    const important = state.requestData.important;
    commit('RESET_STATE', important);
    await Promise.all([
      dispatch('fetchNotes', newRequestData),
      dispatch('fetchNoteCounts'),
      dispatch('fetchPresets'),
    ]);
  },
  async fetchPresets({ commit, state }) {
    let requestData = {
      page: 1,
      pageSize: 50,
      order: 1,
      search: '',
      isPreset: true,
    }
    try {
      let response = await api.get('note', requestData);
      if (response) {
        commit("SET_PRESETS", response.data);
      }
    } catch (error) {
      console.error('Error fetching notes:', error);
    }
  },
  async fetchNotes({ commit, state }, newRequestData) {
    let requestData = null;
    if (newRequestData === null) {
      requestData = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        important: state.requestData.important,
        isPreset: state.requestData.isPreset,
      }
    } else {
      requestData = newRequestData;
    }
    const currentRequestData = state.requestData;
    const pageKey = `${requestData.page}-${requestData.pageSize}-${requestData.order}-${requestData.important ? 1 : 0}-${requestData.isPreset ? 1 : 0}`;
    if (newRequestData !== null && state.pagesLoaded.has(pageKey)) {
      commit('SET_REQUEST_DATA', requestData);
      return;
    }
    if (newRequestData === null || JSON.stringify(requestData) !== JSON.stringify(currentRequestData)) {
      commit('SET_LOADING', true);
      commit('SET_REQUEST_DATA', requestData);
      try {
        let customRequest = null;
        if (requestData.page > 1 && state.notes.items.length === 0) {
          customRequest = {

            page: 1,
            pageSize: requestData.page * requestData.pageSize,
            order: requestData.order,
            search: requestData.search,
            important: requestData.important,
            isPreset: requestData.isPreset,
          }
          await fetchNotesWithRetry(commit, requestData, customRequest, 0);

        }
        else {
          if (requestData.page - state.notes.index > 1)
            for (let page = state.notes.index + 1; page <= requestData.page; page++) {
              customRequest = {
                page: page,
                pageSize: requestData.pageSize,
                order: requestData.order,
                search: requestData.search,
                important: requestData.important,
                isPreset: requestData.isPreset,
              };
              await fetchNotesWithRetry(commit, customRequest, null, 0);
            }
          else {
            await fetchNotesWithRetry(commit, requestData, customRequest, 0);

          }
        }

      } finally {
        commit('SET_LOADING', false);
      }
    }
  },

  async fetchNoteCounts({ commit }) {
    try {
      const response = await api.get('note/count', {});
      if (response) {
        commit("SET_COUNT", response.data);
      }
      return true;
    } catch (error) {
      console.error('Error fetching note counts:', error);
      return false;
    }
  },

  async addNote({ commit, dispatch, state }, note) {
    try {
      const response = await api.insert('note', note);
      if (response) {
        if (note.timelineId) {
          dispatch('timeline/addNoteIntoTimeline', response.data, { root: true });
        }
        $toast.open({
          message: 'Note Added',
          type: 'success',
          duration: 3000,
          dismissible: true
        });
        dispatch('fetchNotesAndCounts', null);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error adding note:', error);
      return false;
    }
  },

  async markAsImportant({ commit, dispatch }, request) {
    try {
      const response = await api.insert('note/markAsImportant', request);
      if (response) {
        commit("UPDATE_NOTES", response.data);
        $toast.open({
          message: 'Note updated as important',
          type: 'info',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error marking note as important:', error);
      return false;
    }
  },

  async markAsNotImportant({ commit, dispatch }, request) {
    try {
      const response = await api.insert('note/markAsNotImportant', request);
      if (response) {
        commit("UPDATE_NOTES", response.data);
        $toast.open({
          message: 'Note updated as not important',
          type: 'info',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error marking note as not important:', error);
      return false;
    }
  },

  async deleteNote({ commit, dispatch, state }, request) {
    try {
      const response = await api.deleteWithRequest('note/delete', request);
      if (response.data) {
        $toast.open({
          message: 'Note Deleted',
          type: 'warning',
          duration: 3000,
          dismissible: true
        });
        commit("REMOVE_NOTES", request.ids);
        if (request.ids > 1 && state.notes.hasNextPage == true) {
          dispatch('fetchNotesAndCounts', null);
        }
        dispatch('fetchNoteCounts')
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error deleting note:', error);
      return false;
    }
  },
  removePage({ state }, page) {
    state.pagesLoaded.delete(page);

  },

  async editNote({ commit }, patchModel) {
    try {
      const response = await api.patch('note', patchModel[0], patchModel[1]);
      if (response) {
        commit("UPDATE_NOTE", response.data);
        $toast.open({
          message: 'Note Updated',
          type: 'warning',
          duration: 3000,
          dismissible: true
        });
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error editing note:', error);
      return false;
    }
  },
  loadImportant({ dispatch, state }, important) {
    if (state.requestData.important !== important) {
      let request = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        important: important,
      }
      dispatch('fetchNotes', request);
    }
  },
  loadPreset({ dispatch, state }, isPreset) {
    if (state.requestData.isPreset !== isPreset) {
      let request = {
        page: 1,
        pageSize: 50,
        order: 1,
        search: '',
        isPreset: isPreset,
      }
      dispatch('fetchNotes', request);
    }
  }
};

async function fetchNotesWithRetry(commit, requestData, customRequest, retryCount) {
  try {
    let response = null;
    let requestParams = { ...requestData };
    if (requestParams.important === false) {
      delete requestParams.important;
    }
    if (requestParams.isPreset === false) {
      delete requestParams.isPreset;
    }
    if (customRequest === null) {
      response = await api.get('note', requestParams);
    } else {
      let customRequestParams = { ...customRequest };
      if (customRequestParams.important === false) {
        delete customRequestParams.important;
      }
      if (customRequestParams.isPreset === false) {
        delete customRequestParams.isPreset;
      }
      response = await api.get('note', customRequestParams);
    }
    if (response) {
      commit("SET_NOTES", { data: response.data, requestData, customRequest });
    }
  } catch (error) {
    console.error('Error fetching notes:', error);
    if (retryCount < 3) {
      await new Promise(resolve => setTimeout(resolve, 10000));
      await fetchNotesWithRetry(commit, requestData, customRequest, retryCount + 1);
    } else {
      console.error('Max retries reached. Failed to fetch notes.');
    }
  }
}


export const mutations = {
  RESET_STATE(state, important) {
    Object.assign(state, initialState(important));
  },
  SET_PRESETS(s, data) {
    s.presetNotes = data.items;
  },
  SET_NOTES(s, { data, requestData, customRequest }) {
    if (requestData.page === 1) {
      s.notes.items = data.items;
      const pageKey = `${requestData.page}-${requestData.pageSize}-${requestData.order}-${requestData.important ? 1 : 0}-${requestData.isPreset ? 1 : 0}`;
      s.pagesLoaded.add(pageKey);
    } else {
      if (s.notes.items.length === 0 || customRequest !== null) {
        s.notes.items = data.items;
        for (let i = 1; i <= requestData.page; i++) {
          let pageKey = `${i}-${requestData.pageSize}-${requestData.order}-${requestData.important ? 1 : 0}-${requestData.isPreset ? 1 : 0}`;
          s.pagesLoaded.add(pageKey);
        }
      }
      else {
        const pageKey = `${requestData.page}-${requestData.pageSize}-${requestData.order}-${requestData.important ? 1 : 0}-${requestData.isPreset ? 1 : 0}`;
        s.pagesLoaded.add(pageKey);
        s.notes.items = [...s.notes.items, ...data.items];
      }
    }
    s.notes.index = requestData.page;
    s.notes.totalCount = data.totalCount;
    if (customRequest !== null) {
      s.notes.totalPages = Math.ceil(data.totalCount / requestData.pageSize);
      s.notes.hasPreviousPage = requestData.page > 1;
      s.notes.hasNextPage = requestData.page < Math.ceil(data.totalCount / requestData.pageSize);
    }
    else {
      s.notes.totalPages = data.totalPages;
      s.notes.hasPreviousPage = data.hasPreviousPage;
      s.notes.hasNextPage = data.hasNextPage;
    }

  },
  SET_LOADING(s, isLoading) {
    s.isLoading = isLoading;
  },
  SET_REQUEST_DATA(s, data) {
    if (s.requestData.pageSize !== data.pageSize || s.requestData.order !== data.order || s.requestData.important !== data.important || s.requestData.isPreset !== data.isPreset) {
      s.pagesLoaded.clear()
      s.notes.items = []
    }
    s.requestData.page = data.page
    s.requestData.pageSize = data.pageSize
    s.requestData.order = data.order
    s.requestData.search = data.search
    s.requestData.important = data.important
    s.requestData.isPreset = data.isPreset
  },

  SET_COUNT(s, response) {
    s.totalCount = response.totalCount;
    s.importantCount = response.importantCount;
    s.presetCount = response.presetCount;
  },
  UPDATE_NOTE(s, response) {
    const note = s.notes.items.find(note => note.id === response.id);
    if (note) {
      note.content = response.content
      note.title = response.title
      note.isImportant = response.isImportant
      note.isPreset = response.isPreset
    }
  },
  UPDATE_NOTES(s, responses) {
    responses.forEach(response => {
      const note = s.notes.items.find(note => note.id === response.id);
      if (note) {
        note.content = response.content
        note.title = response.title
        note.isImportant = response.isImportant
        note.isPreset = response.isPreset
      }
    });
  },
  REMOVE_NOTES(s, ids) {
    s.notes.items = s.notes.items.filter(item => !ids.includes(item.id));
    let totalCount = s.notes.items.length;
    let deletedImportantCount = 0
    s.notes.items = s.notes.items.map(item => {
      if (ids.includes(item.id)) {
        if (item.isImportant) {
          deletedImportantCount++;
        }
        return { ...item, isDeleted: true };
      }
      return item;
    });
    s.notes.totalCount -= ids.length;
    s.totalCount -= ids.length;
    s.notes.totalPages = Math.ceil(totalCount / s.requestData.pageSize);
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
