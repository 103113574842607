import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

const $toast = useToast();

export const state = {
  contact: null,
  contacts: {
    items: [],
    index: 0,
    totalCount: 0,
    totalPages: 0,
    hasPreviousPage: 0,
    hasNextPage: 0,
  },
  isLoading: true,
  allContact: [],
  error: null,
}
export const getters = {
  contact: s => contactId => { return s.allContact.find(item => item.id === contactId) },
  // contact: s => s.contact,
  contacts: s => s.contacts,
  allContact: s => s.allContact,
  isLoading: s => s.isLoading,
  error: s => s.error,
};
export const actions = {

  async getContact({ commit }, contactId) {
    return await api.getById(`contact`, contactId).then(response => {
      if (response) {
        commit("SET_CONTACT", response.data)
      }
      return true
    })
  },
  async fetchContacts({ commit }, requestData) {
    return await api.get(`contact`, requestData).then(response => {
      if (response) {
        commit("SET_CONTACTS", response.data);
      }
      return true;
    });
  },
  async fetchAllContacts({ commit }) {
    return await api.get(`contact/GetAllContacts`, {}).then(response => {
      if (response) {
        commit("SET_ALL_CONTACTS", response.data);
      }
      return true;
    });
  },
  async AddNewContact({ commit, dispatch }, contact) {
    return await api.insert('contact', contact).then(response => {
      if (response) {
        commit("ADD_CONTACT", response.data)
        $toast.open({
          message: 'Contact Added',
          type: 'success',
          duration: 3000,
          dismissible: true
        })
      }
      return true;
    });
  },
  async EditContact({ commit }, patchModel) {
    return await api.patch('contact', patchModel[0], patchModel[1]).then(response => {
      if (response) {
        commit("UPDATE_CONTACT", response.data)
        $toast.open({
          message: 'Contact Updated',
          type: 'warning',
          duration: 3000,
          dismissible: true
        })
        return true;
      }
    });

  },
  async deleteContact({ commit, dispatch }, contactId) {
    return await api.delete(`contact`, contactId)
      .then((response) => {
        if (response) {
          $toast.open({
            message: 'Contact Deleted',
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          commit("REMOVE_CONTACT", contactId);

          return true;
        }
        return false;
      });
  },
}
export const mutations = {
  SET_CONTACT(s, contact) {
    s.contact = contact
    console.log('store.SET_CONTACT', s.contact.name)
    // index = s.contacts.item.findIndex(item => item.id === contact.id);
    // // const foundItem = s.contact[index];
    // if (index === -1) {
    //   s.contacts.item.push(contact);
    // } else {
    //   s.contacts.item[index] = contact;
    // }
    s.isLoading = false;
  },
  SET_CONTACTS(s, contacts) {
    s.contacts = contacts;
    s.isLoading = false;
  },
  SET_ALL_CONTACTS(s, contacts) {
    s.allContact = contacts;
  },
  ADD_CONTACT(s, contact) {
    s.contacts.items.unshift(contact);
  },
  UPDATE_CONTACT(s, contact) {
    s.contacts.items = s.contacts.items.map((item) => (item.id === contact.id ? contact : item))
  },
  REMOVE_CONTACT(s, id) {
    s.contacts.items = s.contacts.items.filter(item => item.id !== id);
  },
}
export default {
  state,
  getters,
  actions,
  mutations
};