import api from '@/lib/services/general.service'
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
const $toast = useToast();
export const state = {
  show: false,
  content: '',
  id: null,
  title: '',
  contact: null,
  timeline: null,
  timelines: [],
  timelinesIsLoading: true,
  timelineIsLoading: true,
}

export const getters = {
  show: x => x.show,
  content: x => x.content,
  id: x => x.id,
  title: x => x.title,
  contact: x => x.contact,
  timeline: x => x.timeline,
  timelines: x => x.timelines,
  isLoading: x => x.timelinesIsLoading,
  timelineIsLoading: x => x.timelineIsLoading
}

export const actions = {
  collapse({ commit }, request) {
    commit("SET_ISCOLLAPSE", request);
  },
  collapseAll({ commit }) {
    commit("SET_ISCOLLAPSE_TRUE_All");
  },
  async showSidePanel({ commit },) {
    commit("SHOW_SIDE_PANEL")
  },
  async fetchTimeline({ commit }, { id, content, title }) {
    commit("SET_TIMELINE_LOADING", true)
    commit("SET_ID", id)
    commit("SET_CONTENT", content)
    commit("SET_TITLE", title)
    return await api.getById(`timeline`, id, '').then(response => {
      if (response) {
        commit("SET_SIDE_PANEL_DATA", response.data)
        commit("SET_TIMELINE_LOADING", false)

      }
      return false;
    });
  },
  async fetchTimelines({ commit }, { id, content, title, contact }) {
    commit("SET_TIMELINES_LOADING", true)
    commit("SET_ID", id)
    commit("SET_CONTENT", content)
    commit("SET_TITLE", title)
    commit("SET_CONTACT", contact)
    return await api.getWithPathById(`timeline`, id, 'all').then(response => {
      if (response) {
        commit("SET_TIMELINE", response.data)
        commit("SET_TIMELINES_LOADING", false)


      }
      return false;
    });
  },
  hideSidePanel({ commit }) {
    commit("HIDE_SIDE_PANEL")
  },
  async markAsRead({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email marked as read'
    } else {
      message = count + ' emails marked as read'
    }

    return api.insert(`timeline/markAsRead`, request)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch('timeline/fetchCount', null, { root: true });
          dispatch('timeline/fetchContact', null, { root: true });
          commit("MARK_AS_READ", request.ids);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          return true;
        }
        return false;
      });
  },
  async markAsUnRead({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email marked as unread'
    } else {
      message = count + ' emails marked as unread'
    }

    return api.insert(`timeline/markAsUnRead`, request)
      .then((response) => {
        if (response && response.status === 200) {
          dispatch('timeline/fetchCount', null, { root: true });
          dispatch('timeline/fetchContact', null, { root: true });
          commit("MARK_AS_UNREAD", request.ids);
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          return true;
        }
        return false;
      });
  },
  async markAsImportant({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email sets as important'
    } else {
      message = count + ' emails sets as important'
    }

    return api.insert(`timeline/markAsImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", { important: true, response: response.data });
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('timeline/fetchCount', null, { root: true });
          return true;
        }
        return false;
      });
  },
  async markAsNotImportant({ commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email sets as not important'
    } else {
      message = count + ' emails sets as not important'
    }

    return api.insert(`timeline/markAsNotImportant`, request)
      .then((response) => {
        if (response) {
          commit("UPDATE_IMPORTANT", { important: false, response: response.data });
          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          dispatch('timeline/fetchCount', null, { root: true });

          return true;
        }
        return false;
      });
  },
  async archiveTimeline({ state, commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email archived'
    } else {
      message = count + ' emails archived'
    }

    return await api.insert(`timeline/archive`, request)
      .then((response) => {
        if (response) {
          dispatch('timeline/fetchCount', null, { root: true });
          commit("REMOVE", request.ids);
          dispatch('timeline/fetchContact', null, { root: true });

          $toast.open({
            message: message,
            type: 'info',
            duration: 3000,
            dismissible: true
          })
          return true;
        }
        return false;
      });
  },
  async deleteTimeline({ state, commit, dispatch }, request) {
    const count = request.ids.length
    let message = ''

    if (count === 1) {
      message = 'Email deleted'
    } else {
      message = count + ' emails deleted'
    }

    return await api.deleteWithRequest(`timeline/moveToBin`, request)
      .then((response) => {
        dispatch('timeline/fetchCount', null, { root: true });
        if (response) {
          commit("REMOVE", request.ids);
          if (state.timelines.length === 0) {
            dispatch('timeline/fetchContact', null, { root: true });
          }
          $toast.open({
            message: message,
            type: 'warning',
            duration: 3000,
            dismissible: true
          })
          return true;
        }
        return false;
      });
  },
}

export const mutations = {
  REMOVE(s, ids) {
    s.timelines = s.timelines.filter(item => !ids.includes(item.id));
  },
  MARK_AS_READ(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.find(t => t.id === response);
      if (timeline) {
        timeline.seen = true;
      }
    })
  },
  MARK_AS_UNREAD(s, response) {
    response.forEach(response => {
      let timeline = s.timelines.find(t => t.id === response);
      if (timeline) {
        timeline.seen = false;
      }
    })
  },
  SHOW_SIDE_PANEL(x) {
    x.show = true
  },
  SET_CONTENT(x, content) {
    x.content = content
  },
  SET_ID(x, id) {
    x.id = id
  },
  SET_TITLE(x, title) {
    x.title = title
  },
  SET_CONTACT(x, contact) {
    x.contact = contact
  },
  SET_TIMELINE(x, timelines) {
    x.timelines = timelines
  },
  SET_SIDE_PANEL_DATA(x, timeline) {
    x.timeline = timeline
  },
  HIDE_SIDE_PANEL(x) {
    x.show = false
  },
  SET_TIMELINES_LOADING(s, isLoading) {
    s.timelinesIsLoading = isLoading;
  },
  SET_TIMELINE_LOADING(s, isLoading) {
    s.timelineIsLoading = isLoading;
  },
  UPDATE_IMPORTANT(s, data) {
    data.response.forEach(response => {
      const timeline = s.timelines.find(t => t.id === response.id);
      if (timeline) {
        timeline.isImportant = data.important;
      }
    })
  },
  SET_ISCOLLAPSE(s, request) {
    const index = s.timelines.findIndex(item => item.id === request.id);
    if (index !== -1) {
      s.timelines[index].isCollapsed = !s.timelines[index].isCollapsed;

    };

  },
  SET_ISCOLLAPSE_TRUE_All(state) {
    state.timelines.forEach((timeline) => {
      timeline.isCollapsed = true;
    });
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}